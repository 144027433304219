import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import BMBoysGirlsImage from '~images/bar-bat-mitzvah/bnei-bnot-mitzvah-ceremony-steps.jpg';

const useStyles = makeStyles(() => ({
  bbm_boys_girls: {
    padding: '100px 0',
    backgroundColor: '#ffffff'
  },
  title_boys_girls: {
    color: '#5a6175',
    fontSize: '40px',
    fontWeight: 'bold',
    margin: '0'
  },
  content_boys_girls: {
    padding: '20px 80px 0 0',
    color: '#5a6175',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '30px',
    margin: '0',
    '& span': {
      color: '#438dce',
      fontWeight: 'bold'
    }
  }
}));

const BarBatMitzvahBoysGirls = () => {
  const classes = useStyles();
  return (
    <section className={classes.bbm_boys_girls}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography component="h2" className={classes.title_boys_girls}>
              For Boys and Girls
            </Typography>
            <Typography component="p" className={classes.content_boys_girls}>
              This course is designed for both girls and boys - for Bar and Bat Mitzvah students.
              The reason for this is simple. Although in many communities boys and girls perform
              mitzvot differently from one another and may celebrate their Bar or Bat Mitzvah
              differently from one another, this course is about <span>BIG QUESTIONS</span> in
              Judaism that apply to everyone equally - regardless of gender, and even regardless of
              age or style of observance.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={BMBoysGirlsImage}
              alt="Bnei Bnot Mitzvah ceremony steps"
              style={{ width: '100%', maxWidth: '272px' }}
              className="lazyload"
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default BarBatMitzvahBoysGirls;
