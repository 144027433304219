import React from 'react';
import PropTypes from 'prop-types';
import { globalHistory } from '@reach/router'; // eslint-disable-line
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  socialBtn: {
    display: 'inline-block',
    margin: '0px 8px',
    outline: '0',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    }
  }
});

const Social = ({ title, size, round, facebookIcon, twitterIcon, emailIcon }) => {
  const classes = useStyles();
  const { href } = globalHistory.location;
  const socialIcon = {
    size,
    round
  };

  return (
    <div>
      <FacebookShareButton url={href} className={classes.socialBtn}>
        {facebookIcon || <FacebookIcon {...socialIcon} />}
      </FacebookShareButton>
      <TwitterShareButton
        url={href}
        title={title}
        via="joinalephbeta"
        className={classes.socialBtn}
      >
        {twitterIcon || <TwitterIcon {...socialIcon} />}
      </TwitterShareButton>
      <EmailShareButton
        url={href}
        subject="Check out Aleph Beta!"
        body={`${title}`}
        className={classes.socialBtn}
      >
        {emailIcon || <EmailIcon {...socialIcon} />}
      </EmailShareButton>
    </div>
  );
};

Social.propTypes = {
  size: PropTypes.number,
  round: PropTypes.bool,
  title: PropTypes.string,
  facebookIcon: PropTypes.element,
  twitterIcon: PropTypes.element,
  emailIcon: PropTypes.element
};

Social.defaultProps = {
  size: 42,
  round: true,
  title: 'Check out Aleph Beta!',
  facebookIcon: undefined,
  twitterIcon: undefined,
  emailIcon: undefined
};

export default Social;
