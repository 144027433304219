import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import BMSuggestionCheckmarkImage from '~images/bar-bat-mitzvah/suggestion-checkmark.png';
import BMGirlsPlanning from '~images/bar-bat-mitzvah/bar-bat-boy-girl-mitzvah-planning.jpg';

const useStyles = makeStyles(theme => ({
  bbm_find_fit: {
    padding: '100px 0',
    backgroundColor: '#f1eee9'
  },
  title_find_fit: {
    color: '#5a6175',
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0'
  },
  content_find_fit: {
    padding: '20px 0 0 0',
    color: '#5a6175',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '30px',
    margin: '0',
    '& span': {
      color: '#438dce',
      fontWeight: 'bold'
    }
  },
  suggestions_wrapper: {
    padding: '40px 0 0 0'
  },
  mother_daughter_photo: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '20px',
      width: '100%'
    }
  },
  suggestion: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '18px',
    fontWeight: '400',
    color: '#5a6175',
    paddingTop: '0.4rem',
    paddingBottom: '20px',
    paddingLeft: '40px',
    lineHeight: '30px',
    marginTop: '-0.4rem',
    background: `url(${BMSuggestionCheckmarkImage}) no-repeat 0 0.52rem`,
    backgroundSize: '26px 26px',
    margin: '0',
    '& p': {
      margin: '0'
    },
    '& span': {
      color: '#438dce',
      fontWeight: 'bold'
    }
  },
  media: {
    maxWidth: '100%',
    display: 'flex'
  }
}));

const BarBatMitzvahFindFit = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <section className={classes.bbm_find_fit}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography component="h2" className={classes.title_find_fit}>
              Find the Perfect Fit for Your Schedule
            </Typography>
            <Typography component="p" className={classes.content_find_fit}>
              The course is broken up into <span>six</span> sections, that take around{' '}
              <span>45 minutes</span> each. We give you both a video and printable version of the
              course, so that you can choose the best way to use it for you and your child. <br />
              <br />
              There are many ways the course can be taken. Here are some of our suggestions. You
              can:
            </Typography>
            <div className={classes.suggestions_wrapper}>
              <Grid
                container
                direction={isSmallerThanMd ? 'column-reverse' : 'row'}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={8} md={6}>
                  <div className="suggestions">
                    <div className={classes.suggestion}>
                      <Typography component="p">
                        <span>Watch</span> the entire video course together with your daughter or
                        son
                      </Typography>
                    </div>
                    <div className={classes.suggestion}>
                      <Typography component="p">
                        <span>Watch</span> it separately from your child, and then set aside times
                        for discussion when you’re both available
                      </Typography>
                    </div>
                    <div className={classes.suggestion}>
                      <Typography component="p">
                        <span>Print</span> the guide version and spend a few Shabbat afternoons or
                        Sunday mornings going through it together with you child
                      </Typography>
                    </div>
                    <div className={classes.suggestion}>
                      <Typography component="p">
                        <span>Watch or read</span> through the entire course in just one or two
                        sittings, on your morning commute, on an airplane, a free evening, etc. Then
                        get together to discuss it with your child at a time that works for both of
                        you
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <div className={classes.mother_daughter_photo}>
                    <img
                      src={BMGirlsPlanning}
                      alt="Bar Bat Mitzvah planning for boys and girls"
                      className={classes.media}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <Typography component="p" className={classes.content_find_fit}>
              In short… do what works for you, and be creative! Find a way to make this a fun and
              enjoyable experience for you both!
              <br />
              <br />
              And, if you simply can’t make the time to do the course yourself, your child can also
              take this course with a <span>tutor</span>, a<span> Rabbi</span>, an older{' '}
              <span>sibling</span>, or even a <span>group</span> of Bar and Bat Mitzvah students.{' '}
              <br />
              <br />
              We’re handing you the resources, it’s up to you to use them in the way that best suits
              your family’s needs.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default BarBatMitzvahFindFit;
