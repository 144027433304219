/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import BMHowToPrepareImage from '~images/bar-bat-mitzvah/how-to-prepare-bar-bat-mitzvah-kids-parents.jpg';
import BMYadImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-online-preparation-process.png';

const useStyles = makeStyles(theme => ({
  bbm_why: {
    padding: '100px 0',
    backgroundColor: '#ffffff'
  },
  title_why: {
    fontFamily: "'Raleway', sans-serif",
    color: '#5a6175',
    fontSize: '40px',
    fontWeight: 'bold',
    margin: '0'
  },
  content_why: {
    padding: '20px 0 0 0',
    color: '#5a6175',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '30px',
    margin: '0',
    '& span': {
      color: '#438dce',
      fontWeight: 'bold',
      '&.popup-link': {
        color: '#438dce',
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
          opacity: '0.9'
        }
      }
    }
  },
  content_why_right: {
    float: 'right',
    width: 'auto',
    height: 'auto',
    maxWidth: '40%',
    margin: '0 0 0 60px',
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      position: 'relative',
      margin: '0 0 20px 20px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      position: 'relative',
      margin: '0 0 20px 0'
    }
  },
  relevant_questions: {
    margin: '-8px 0 0 0',
    padding: '20px',
    backgroundColor: '#f6f3ee',
    '& h3': {
      color: '#5a6175',
      fontWeight: 'bold',
      margin: '0',
      '& span': {
        color: '#76c79d'
      }
    },
    '& ul': {
      listStyle: 'none',
      paddingLeft: '40px',
      '& li': {
        position: 'relative',
        padding: '10px',
        '&:before': {
          content: "''",
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          background: '#76c79d',
          position: 'absolute',
          left: '-15px',
          top: '15px',
          zIndex: '2'
        },
        '&:not(:last-child):after': {
          content: "''",
          width: '2px',
          height: '120%',
          background: '#e6e3de',
          position: 'absolute',
          left: '-10px',
          top: '20px',
          zIndex: '1'
        },
        '& p': {
          fontFamily: "'Roboto', sans-serif",
          color: '#5a6175',
          margin: '0'
        }
      }
    }
  },
  yad: {
    position: 'relative',
    '& img': {
      position: 'absolute',
      right: '0px',
      bottom: '-20px',
      transform: 'rotate(-45deg)',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '35%',
        right: '-30px',
        bottom: '-60px'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '50%',
        right: '0px',
        bottom: '-110px'
      }
    }
  }
}));

const BarBatMitzvahWhy = props => {
  const classes = useStyles();
  return (
    <section className={classes.bbm_why}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <h2 className={classes.title_why}>Why This Course?</h2>
            <div className={classes.content_why_right}>
              <img
                src={BMHowToPrepareImage}
                alt="How to prepare Bar Bat Mitzvah for kids and parents"
              />
              <div className={classes.relevant_questions}>
                <Typography component="h3">
                  Some of the <span>relevant</span> questions answered include:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body2" component="p">
                      What does it mean to be a Jewish adult, and why does it happen at this age?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" component="p">
                      How can a 12 or 13 year old form a relationship with God?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" component="p">
                      What is Judaism all about?
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" component="p">
                      Why do we have all these laws? And what’s the deeper meaning behind the
                      mitzvot?
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
            <Typography component="p" className={classes.content_why}>
              For most kids, learning for their Bar and Bat Mitzvah is a one shot deal. They put in
              a few months worth of work to prepare them to perform for one day. Oftentimes they’ll
              read from the Torah or lead part of the davening, and they’ll do some learning so that
              they’ll have something to talk about it in their speech. And then it’s over. Kiddush,
              party and we’re done. <br />
              <br />
              But you know what they’re missing out on? The actual meaning of their Bar or Bat
              Mitzvah. It’s not just a glorified birthday party, or a course in Public Speaking 101!
              It’s meant to be{' '}
              <span>
                a stepping stone that catapults them into a life of inspired Jewish living and
                learning!
              </span>{' '}
              But when the Bar or Bat Mitzvah becomes a one-time experience, and a one-time
              performance, the chances for a lasting effect are slim. <br />
              <br />
              That’s what sets this course apart from your typical Bar and Bat Mitzvah learning.{' '}
              <span>
                The course addresses some of the biggest, most relevant questions for entering the
                Bar and Bat Mitzvah stage. Questions that will continue to be meaningful and
                relevant as your child grows.
              </span>
              <br />
              <br />
              Best of all, we’ve created this course for you to take together with your child, at
              your own convenience! You don’t have to just be the party planner for your child’s Bar
              or Bat Mitzvah. We’re giving you everything you need to guide your child through this
              once-in-a-lifetime experience. <br />
              <br />
              You’ll receive a six part video series with guided questions and points for
              discussion; an illustrated printer-friendly guide; a parents’ manual to making a
              meaningful Bar or Bat Mitzvah; and access to Aleph Beta’s exclusive online video
              library! <br />
              <br />
              So what are you waiting for?{' '}
              <span className="popup-link" onClick={props.showSalePopup}>
                Sign up now, and let the journey begin!
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.yad}>
        <img src={BMYadImage} alt="Bar Bat Mitzvah online preparation process" />
      </div>
    </section>
  );
};

export default BarBatMitzvahWhy;
