import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';

import BMHeroBgImage from '~images/bar-bat-mitzvah/bm-hero-bg.jpg';
import BMABLogoImage from '~images/bar-bat-mitzvah/jewish-bar-bat-mitzvah-aleph-beta.svg';
import BMHeroVideoThumbnailImage from '~images/bar-bat-mitzvah/bm-hero-video-thumbnail.jpg';
import BMBecomingBMGirlsImage from '~images/bar-bat-mitzvah/becoming-bat-mitzvah-girls.png';
import BMBecomingBMBoysImage from '~images/bar-bat-mitzvah/becoming-bar-mitzvah-boys.png';

const useStyles = makeStyles(theme => ({
  bbm_hero: {
    padding: '0',
    backgroundColor: '#fddea8',
    backgroundImage: `url(${BMHeroBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    textAlign: 'center',
    backgroundAttachment: 'relative',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover'
  },
  bbm_hero_body: {
    textAlign: 'center',
    padding: '3rem 2rem',
    '& h1': {
      fontWeight: '700',
      fontSize: '4rem',
      color: '#5a6175',
      lineHeight: '4rem',
      margin: '4px 0',
      fontFamily: "'Raleway', sans-serif",
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
        lineHeight: '2.5rem'
      }
    },
    '& h3': {
      fontSize: '1.5rem',
      fontWeight: '500',
      color: '#5a6175',
      margin: '0',
      fontFamily: "'Raleway', sans-serif",
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem'
      },
      '& span': {
        color: '#76c79d',
        fontWeight: 'bold'
      }
    }
  },
  bbm_ab_logo: {
    width: '234px',
    height: '50px',
    pointerEvents: 'none',
    WebkitUserDrag: 'none',
    KhtmlUserDrag: 'none',
    MozUserDrag: 'none',
    OUserDrag: 'none'
  },
  video_thumbnail: {
    cursor: 'pointer',
    WebkitUserDrag: 'none',
    KhtmlUserDrag: 'none',
    MozUserDrag: 'none',
    OUserDrag: 'none',
    padding: '30px 0',
    height: 'auto',
    maxWidth: '100%',
    '&:hover': {
      opacity: '0.95'
    }
  },
  bbm_hero_girl: {
    position: 'relative',
    '& img': {
      pointerEvents: 'none',
      position: 'absolute',
      left: '0',
      bottom: '-3rem',
      width: '100%',
      maxWidth: '244px',
      height: 'auto',
      WebkitUserDrag: 'none',
      KhtmlUserDrag: 'none',
      MozUserDrag: 'none',
      OUserDrag: 'none',
      [theme.breakpoints.down('md')]: {
        left: '-60px',
        maxWidth: '200px'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  bbm_hero_boy: {
    position: 'relative',
    '& img': {
      pointerEvents: 'none',
      position: 'absolute',
      right: '0',
      bottom: '-3rem',
      width: '100%',
      maxWidth: '248px',
      height: 'auto',
      WebkitUserDrag: 'none',
      KhtmlUserDrag: 'none',
      MozUserDrag: 'none',
      OUserDrag: 'none',
      [theme.breakpoints.down('md')]: {
        right: '-60px',
        maxWidth: '204px'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  }
}));

const BarBatMitzvahHero = () => {
  const classes = useStyles();
  return (
    <section className={classes.bbm_hero}>
      <Container>
        <div className={classes.bbm_hero_body}>
          <img
            className={classes.bbm_ab_logo}
            src={BMABLogoImage}
            alt="Jewish Bar and Bat Mitzvah Aleph Beta"
          />
          <Typography component="h1">
            Bar and Bat Mitzvah
            <br />
            Prep Course
          </Typography>
          <Link
            className="hero-play-btn various wistia-popover[height=720,playerColor=f18744,width=1280]"
            to="https://fast.wistia.net/embed/iframe/kx06tvq3u5?popover=true"
          >
            <img className={classes.video_thumbnail} src={BMHeroVideoThumbnailImage} alt="" />
          </Link>
          <Typography variant="h3" component="h3">
            Give your Child a <span>Life Changing</span> Bar or Bat Mitzvah
          </Typography>
          <div className={classes.bbm_hero_girl}>
            <img src={BMBecomingBMGirlsImage} alt="Becoming Bat Mitzvah girls" />
          </div>
          <div className={classes.bbm_hero_boy}>
            <img src={BMBecomingBMBoysImage} alt="Becoming Bar Mitzvah boys" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BarBatMitzvahHero;
