/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import ArrowRightImage from '~images/bar-bat-mitzvah/bm-testimonials-arrow-right.png';
import ArrowLeftImage from '~images/bar-bat-mitzvah/bm-testimonials-arrow-left.png';

const BarBatMitzvahArrow = ({ className, style, onClick, arrow }) => (
  <div>
    {arrow === 'next' ? (
      <div
        className={className}
        style={{ ...style, display: 'block', right: '-65px' }}
        onClick={onClick}
      >
        <img src={ArrowRightImage} alt="arrow-right" />
      </div>
    ) : (
      <div
        className={className}
        style={{ ...style, display: 'block', left: '-65px' }}
        onClick={onClick}
      >
        <img src={ArrowLeftImage} alt="arrow-left" />
      </div>
    )}
  </div>
);

BarBatMitzvahArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  arrow: PropTypes.string.isRequired
};
BarBatMitzvahArrow.defaultProps = {
  className: null,
  style: null,
  onClick: null
};

export default BarBatMitzvahArrow;
