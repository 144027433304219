/* eslint-disable */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import CheckIcon from '@material-ui/icons/Check';

import Container from '~components/Container';
import BMVidPrepImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-video-preparation.svg';
import BMParentPlanImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-parents-planning.svg';

const useStyles = makeStyles(theme => ({
  icon: {
    margin: '0 0 0 4px',
    fontSize: '18px',
    position: 'relative',
    top: '4px',
    width: '18px',
    height: '18px'
  },
  bbm_info: {
    backgroundColor: '#f1eee9',
    padding: '24px 0'
  },
  col: {
    fontFamily: "'Raleway', sans-serif",
    textAlign: 'center',
    padding: '30px 0',
    width: '100%',
    '& h3': {
      fontFamily: "'Raleway', sans-serif",
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: '600',
      color: '#5a6175',
      padding: '12px 0',
      margin: '0'
    },
    '& p': {
      fontSize: '0.9rem',
      fontWeight: '500',
      color: '#5a6175',
      padding: '0 20px',
      margin: '0'
    },
    '& bbm_icons': {
      fill: '#5a6175'
    }
  },
  col_cta: {
    textAlign: 'center'
  },
  cta_wrapper: {
    padding: '0 20px'
  },
  cta_title: {
    fontFamily: "'Raleway', sans-serif",
    color: '#4a4a4a',
    fontSize: '1.2rem',
    fontWeight: '600',
    paddingBottom: '6px',
    margin: '0'
  },
  all_access_btn: {
    backgroundColor: '#438dce',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '12px 0'
  },
  basic_plan_btn: {
    backgroundColor: '#5a6175',
    color: '#ffffff',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: '#444958'
    }
  },
  gift_btn: {
    backgroundColor: 'transparent',
    color: '#5a6175',
    border: '2px solid #5a6175',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '10px 0'
  },
  cta_pricing_desc: {
    fontFamily: "'Roboto', sans-serif",
    color: '#5a6175',
    fontSize: '0.9rem',
    paddingTop: '4px'
  },
  cta_more_info_icon: {
    color: '#5a6175'
  },
  side_borders: {
    borderLeft: '1px solid #cecbc8',
    borderRight: '1px solid #cecbc8',
    [theme.breakpoints.down('sm')]: {
      border: 'none'
    }
  },
  or_divider: {
    position: 'relative',
    lineHeight: '16px',
    fontSize: '12px',
    letterSpacing: '1px',
    margin: '9px 0',
    color: '#4a4a4a',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '0',
      width: '100%',
      height: '1px',
      background: '#cecbc8'
    },
    '& span': {
      position: 'relative',
      background: '#f1eee9',
      padding: '0 7px'
    }
  },
  iconPosition: {
    display: 'flex',
    alignItems: 'center'
  },
  iconStyle: {
    marginRight: theme.spacing(1)
  }
}));

const BM_BENEFITS = [
  'Bar and Bat Mitzvah Prep Videos',
  'Illustrated Study Guide',
  'Parents’ Manual',
  'Exclusive Podcast',
  'Access to Prep Course Facebook Group'
];

const TooltipContent = () => {
  const classes = useStyles();

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        You will get:
      </Typography>
      <Typography variant="h6" gutterBottom>
        Bar and Bat Mitzvah Prep Course:
      </Typography>
      {BM_BENEFITS &&
        BM_BENEFITS.map(benefit => (
          <Typography key={benefit} variant="subtitle1" className={classes.iconPosition}>
            <CheckIcon color="primary" className={classes.iconStyle} /> {benefit}
          </Typography>
        ))}

      <Typography variant="h6" className={classes.iconPosition}>
        <CheckIcon color="primary" className={classes.iconStyle} />
        1-Year Premium Membership
      </Typography>
    </Box>
  );
};

const BarBatMitzvahInfo = props => {
  const classes = useStyles();
  const { isBarMitzvah } = props;
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <section className={classes.bbm_info}>
      <Container>
        <Grid
          container
          direction={isSmallerThanMd ? 'column-reverse' : 'row'}
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={12} md={4} className={classes.col}>
            <img
              src={BMVidPrepImage}
              className="bbm-icons"
              width="40"
              height="30"
              alt="Bar Bat Mitzvah video preparation"
            />
            <Typography component="h3">6 part video series</Typography>
            <Typography>
              An interactive journey led by Rabbi Fohrman and Imu Shalev tackling some of
              Judaism&apos;s biggest questions
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={classNames(classes.col, classes.side_borders)}
          >
            <img
              src={BMParentPlanImage}
              className="bbm-icons"
              width="40"
              height="30"
              alt="Bar Bat Mitzvah parents planning"
            />
            <Typography component="h3">parents&apos; manual</Typography>
            <Typography>
              Full of resources and materials to help you guide your child through this
              once-in-a-lifetime experience
            </Typography>
          </Grid>
          {!isBarMitzvah && (
            <Grid item xs={12} sm={12} md={4} className={classes.col}>
              <div className={classes.cta_wrapper}>
                <Typography variant="h5" component="h5" className={classes.cta_title}>
                  Get started now
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.all_access_btn}
                  onClick={() => props.goToCheckout({ isAllAccess: true, isGift: false })}
                  fullWidth
                >
                  get all-access - Subscribe Today
                </Button>
                <Tooltip title={<TooltipContent />}>
                  <div className={classes.cta_pricing_desc}>
                    $180 - Access to everything
                    <HelpOutlinedIcon className={classes.icon} />
                  </div>
                </Tooltip>
              </div>
            </Grid>
          )}
          {isBarMitzvah && (
            <Grid item xs={12} sm={12} md={4} className={classes.col}>
              <div className={classes.cta_wrapper}>
                <Typography variant="h5" component="h5" className={classes.cta_title}>
                  Thanks for purchasing!
                </Typography>
                <Button
                  component={GatsbyLink}
                  to="/bar-bat-mitzvah/lesson-plan"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.all_access_btn}
                  fullWidth
                >
                  View Course
                </Button>

                <div className={classes.or_divider}>
                  <span>OR</span>
                </div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="large"
                      className={classes.gift_btn}
                      onClick={() => props.showSalePopup({ gift: true })}
                      fullWidth
                    >
                      gift
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
    </section>
  );
};

export default BarBatMitzvahInfo;
