import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';

import Container from '~components/Container';
import BMPresentersBgImage from '~images/bar-bat-mitzvah/bm-presenters-bg.png';
import BMTeacherRFSmImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-teacher-fohrman-sm.png';
import BMTeacherRFImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-teacher-fohrman.png';
import BMTeacherShalevSmImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-teacher-shalev-sm.png';
import BMTeacherShalevImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-teacher-shalev.png';
import { OwnWebsitePromoLink } from '~components/Link';

const useStyles = makeStyles(theme => ({
  bbm_presenters: {
    backgroundColor: '#626a7f',
    backgroundImage: `url(${BMPresentersBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    textAlign: 'center',
    backgroundAttachment: 'relative',
    WebkitBackgroundSize: 'contain',
    MozBackgroundSize: 'contain',
    OBackgroundSize: 'contain',
    backgroundSize: 'contain',
    padding: '100px 0 40px 0'
  },
  presenter: {
    backgroundColor: '#8089a7',
    padding: '40px 50px 40px 0px',
    textAlign: 'left',
    marginBottom: '60px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 50px 40px 40px !important'
    }
  },
  imu_pad: {
    padding: '40px 0px 40px 50px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 50px 40px 40px !important'
    }
  },
  rf_img_sm: {
    width: '150px',
    height: '150px'
  },
  presenter_img_rf: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  rf_img_lg: {
    position: 'absolute',
    left: '0px',
    bottom: '-40px',
    maxWidth: '150px'
  },
  imu_img_sm: {
    width: '150px',
    height: '150px'
  },
  presenter_img_imu: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  imu_img_lg: {
    position: 'absolute',
    right: '0px',
    bottom: '-40px',
    maxWidth: '190px'
  },
  presenter_name: {
    color: '#fddea8',
    fontSize: '26px',
    fontWeight: '700',
    paddingBottom: '10px',
    margin: '0'
  },
  presenter_bio: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontFamily: "'Roboto', sans-serif",
    lineHeight: '20px',
    margin: '0',
    '& a': {
      color: 'rgba(255, 255, 255, 0.8) !important'
    }
  }
}));

const BarBatMitzvahPresenters = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <section className={classes.bbm_presenters}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={10}>
            <div className={classes.presenter}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                  <Hidden mdUp>
                    <img
                      src={BMTeacherRFSmImage}
                      className={classes.rf_img_sm}
                      alt="Bar Bat Mitzvah Teacher Fohrman Profile"
                    />
                  </Hidden>
                  <div className={classes.presenter_img_rf}>
                    <Hidden smDown>
                      <img
                        src={BMTeacherRFImage}
                        className={classes.rf_img_lg}
                        alt="Bar Bat Mitzvah Teacher Fohrman"
                      />
                    </Hidden>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <Typography component="h2" className={classes.presenter_name}>
                    Rabbi David Fohrman
                  </Typography>
                  <Typography className={classes.presenter_bio}>
                    Is the founder and CEO of Aleph Beta, a non-profit media company that produces
                    high-quality Torah videos highlighting a unique methodology in studying Torah
                    text in a way that is both intellectually rigorous and emotionally engaging.
                    Rabbi Fohrman also lectures internationally on Biblical themes. He served as a
                    senior writer and editor for ArtScroll’s Schottenstein Edition of the Talmud,
                    and taught Biblical themes at Johns Hopkins University. Rabbi Fohrman’s recorded
                    lectures and animated videos are available at <OwnWebsitePromoLink />. His
                    published works include The Beast That Crouches at the Door, The Queen You
                    Thought You Knew, and The Exodus You Almost Passed Over.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classNames(classes.presenter, classes.imu_pad)}>
              <Grid container direction={isSmallerThanMd ? 'column-reverse' : 'row'}>
                <Grid item xs={12} sm={12} md={9}>
                  <Typography component="h2" className={classes.presenter_name}>
                    Immanuel (aka Imu) Shalev
                  </Typography>
                  <Typography className={classes.presenter_bio}>
                    Is the Chief Operating Officer at Aleph Beta. He works closely with Rabbi David
                    Fohrman and a team of talented writers, video editors and web developers on
                    creating video presentations for the meaningful study of Jewish text. Immanuel
                    co-hosted “The Parsha Experiment”, a series of videos with the aim of exploring
                    the Torah’s overall storyline. Immanuel previously served as a singer and
                    director of the Maccabeats and wrote and produced the viral video “Candlelight”
                    with over 14 million hits on Youtube. He is passionate about bringing meaning to
                    Jewish education through the medium of video and technology. Immanuel graduated
                    from Fordham Law School and Yeshiva University.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Hidden mdUp>
                    <img
                      src={BMTeacherShalevSmImage}
                      className={classes.imu_img_sm}
                      alt="Bar Bat Mitzvah Teacher Shalev Profile"
                    />
                  </Hidden>
                  <div className={classes.presenter_img_imu}>
                    <Hidden smDown>
                      <img
                        src={BMTeacherShalevImage}
                        className={classes.imu_img_lg}
                        alt="Bar Bat Mitzvah Teacher Shalev"
                      />
                    </Hidden>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default BarBatMitzvahPresenters;
