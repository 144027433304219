/* eslint-disable */
import React from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BarBatMitzvahArrow from './BarBatMitzvahArrow';
import Container from '~components/Container';
import BMTestimonialFImage from '~images/bar-bat-mitzvah/bm-testimonial-f.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  bbm_testimonials: {
    padding: '100px 0',
    backgroundColor: '#9edcbd',
    textAlign: 'center',
    '& .slick-prev': {
      width: '56px',
      height: '56px'
    },
    '& .slick-next': {
      width: '56px',
      height: '56px'
    },
    '& .slick-prev:before': {
      display: 'none'
    },
    '& .slick-next:before': {
      display: 'none'
    },
    '& .slick-prev:hover': {
      opacity: '0.7'
    },
    '& .slick-next:hover': {
      opacity: '0.7'
    },
    '& .slick-slide': {
      outline: 'none !important'
    },
    '& .slick-slide *': {
      outline: 'none !important'
    }
  },
  testimonial_summary: {
    fontFamily: "'Roboto', sans-serif",
    color: '#5a6175',
    fontSize: '30px',
    fontWeight: '600',
    paddingBottom: '14px',
    margin: '0'
  },
  testimonial_full: {
    fontFamily: "'Roboto', sans-serif",
    color: '#5a6175',
    fontSize: '18px',
    lineHeight: '34px',
    paddingBottom: '20px',
    margin: '0'
  },
  testimonial_person_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  testimonial_name: {
    marginLeft: '12px',
    '& span': {
      fontWeight: 'bold'
    }
  },
  testimonial_image: {
    width: '40px',
    height: '40px'
  }
}));

const BarBatMitzvahTestimonials = () => {
  const classes = useStyles();
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <BarBatMitzvahArrow arrow="next" />,
    prevArrow: <BarBatMitzvahArrow arrow="prev" />
  };
  return (
    <section className={classes.bbm_testimonials}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={8}>
            <Slider {...settings}>
              <div>
                <Typography component="h3" className={classes.testimonial_summary}>
                  Great format for learning together!
                </Typography>
                <Typography component="p" className={classes.testimonial_full}>
                  "Loved the question/discussion time. Fascinating. We really learned together -
                  probably for the first time in a deep way - and that was wonderful. Great format
                  for learning together! Love the videos and the chance to discuss and learn torah
                  together - a real gift!”
                </Typography>
                <div className={classes.testimonial_person_wrapper}>
                  <img className={classes.testimonial_image} src={BMTestimonialFImage} alt="" />
                  <Typography component="p" className={classes.testimonial_name}>
                    <span>Chaya L.</span> | Customer
                  </Typography>
                </div>
              </div>
              <div>
                <Typography component="h3" className={classes.testimonial_summary}>
                  It was accessible and interesting to us both!
                </Typography>
                <Typography component="p" className={classes.testimonial_full}>
                  "As a parent, it was great to be reminded... that Bar and Bat mitzvah kids are
                  still kids. I felt the content engaged both me and my son, was neither too easy
                  for me or too challenging for him, it was accessible and interesting to us both.”
                </Typography>
                <div className={classes.testimonial_person_wrapper}>
                  <img className={classes.testimonial_image} src={BMTestimonialFImage} alt="" />
                  <Typography component="p" className={classes.testimonial_name}>
                    <span>Nava C.</span> | Customer
                  </Typography>
                </div>
              </div>
              <div>
                <Typography component="h3" className={classes.testimonial_summary}>
                  Clearly a lot of thought went into this program!
                </Typography>
                <Typography component="p" className={classes.testimonial_full}>
                  "Clearly a lot of thought went into this program and that is very appreciated...
                  my older girls 14 and 15 found it VERY interesting and pulled chairs over to join
                  us.”
                </Typography>
                <div className={classes.testimonial_person_wrapper}>
                  <img className={classes.testimonial_image} src={BMTestimonialFImage} alt="" />
                  <Typography component="p" className={classes.testimonial_name}>
                    <span>Karen A.</span> | Customer
                  </Typography>
                </div>
              </div>
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default BarBatMitzvahTestimonials;
