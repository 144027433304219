import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  fixedStyle: props => ({
    position: 'fixed',
    maxWidth: '100vw',
    WebkitTransition: 'all .3s ease-in-out',
    MozTransition: 'all .3s ease-in-out',
    OTransition: 'all .3s ease-in-out',
    transition: 'all .3s ease-in-out',
    top: props.isBottom ? 'unset' : 0,
    bottom: props.isBottom ? 0 : 'unset',
    left: 0,
    right: 0,
    zIndex: 3
  }),
  hiddenStyle: props => ({
    WebkitTransform: props.isBottom ? 'translateY(100%)' : 'translateY(-100%)',
    MsTransform: props.isBottom ? 'translateY(100%)' : 'translateY(-100%)',
    transform: props.isBottom ? 'translateY(100%)' : 'translateY(-100%)'
  }),
  scrolledInStyle: props => ({
    WebkitTransform: props.isBottom ? 'unset' : 'translateY(49px)',
    MsTransform: props.isBottom ? 'unset' : 'translateY(49px)',
    transform: props.isBottom ? 'unset' : 'translateY(49px)'
  })
});

const HelloBarScroll = ({ scrollInHeight, children, isBottom, ...rest }) => {
  const classes = useStyles({ isBottom });
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const scrollObserver = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', scrollObserver);

    return () => {
      window.removeEventListener('scroll', scrollObserver);
    };
  }, []);

  return (
    <div
      className={classNames(
        classes.fixedStyle,
        scrollPosition >= scrollInHeight ? classes.scrolledInStyle : classes.hiddenStyle
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

HelloBarScroll.propTypes = {
  children: PropTypes.node.isRequired,
  scrollInHeight: PropTypes.number,
  isBottom: PropTypes.bool
};

HelloBarScroll.defaultProps = {
  scrollInHeight: 50,
  isBottom: false
};

export default HelloBarScroll;
