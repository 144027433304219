import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { navigate, Link as GatsbyLink } from 'gatsby';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import Popups from '~layout/Popups';
import Container from '~components/Container';
import HelloBarScroll from '~components/hello-bar/HelloBarScroll';
import BarBatMitzvahHero from '~components/bar-bat-mitzvah/BarBatMitzvahHero';
import BarBatMitzvahInfo from '~components/bar-bat-mitzvah/BarBatMitzvahInfo';
import BarBatMitzvahPresenters from '~components/bar-bat-mitzvah/BarBatMitzvahPresenters';
import BarBatMitzvahWhy from '~components/bar-bat-mitzvah/BarBatMitzvahWhy';
import BarBatMitzvahAbout from '~components/bar-bat-mitzvah/BarBatMitzvahAbout';
import BarBatMitzvahTestimonials from '~components/bar-bat-mitzvah/BarBatMitzvahTestimonials';
import BarBatMitzvahBoysGirls from '~components/bar-bat-mitzvah/BarBatMitzvahBoysGirls';
import BarBatMitzvahFindFit from '~components/bar-bat-mitzvah/BarBatMitzvahFindFit';
import BarBatMitzvahOrderNow from '~components/bar-bat-mitzvah/BarBatMitzvahOrderNow';
import BarBatMitzvahSharing from '~components/bar-bat-mitzvah/BarBatMitzvahSharing';

import products from '~utils/products';
import useCheckout from '~hooks/useCheckout';
import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const seo = {
  seo_title: 'Bar and Bat Mitzvah | Preparation for Parents & Children',
  seo_description:
    'Becoming a Bar or Bat Mitzvah is an important milestone. How can parents prepare their child spiritually and mentally for adulthood? Our video course shows you how.',
  seo_keywords:
    'bar bat mitzvah, bar bat mitzvah preparation, bar bat mitzvah planning, bar bat mitzvah process, bar bat mitzvah ceremony'
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  hellobarscroll: {
    backgroundColor: 'rgba(75, 81, 97, 0.9)',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 0'
    }
  },
  watchTrailerBtn: {
    backgroundColor: '#3b404d',
    fontSize: '18px',
    fontWeight: '500',
    textTransform: 'uppercase',
    border: '1px #7b808f solid',
    color: '#ffffff',
    textDecoration: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#424753'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '8px 20px'
    }
  },
  hbTitle: {
    color: 'white',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'center',
    padding: '24px 0'
  },
  watchBtnContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  accessBtnContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  getStartedBtn: {
    fontSize: '18px',
    fontWeight: '500',
    textTransform: 'uppercase'
  }
}));

const BM = () => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isBarMitzvah } = session;

  const [popup, setPopup] = useState({ popup: null, gift: false });
  const [, setCheckoutState] = useCheckout();
  const useMountEffect = f => useEffect(f, []);
  useMountEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://fast.wistia.com/assets/external/popover-v1.js';
    script.async = true;
    document.body.appendChild(script);
  });
  const goToCheckout = ({ isAllAccess = false, isGift = false }) => {
    const planId = isAllAccess ? 2 : 31;
    const product = products.find(p => p.id === planId);

    if (isMarketingSite) {
      redirectToMembersCheckout(product, 'year', { isAllAccess, isGift });
    } else {
      setCheckoutState({ product, billingCycle: 'year', isAllAccess, isGift });
      navigate('/checkout');
    }
  };

  const showSalePopup = ({ gift = false }) => {
    setPopup({ popup: 30, gift });
  };
  const handleClose = () => {
    setPopup({ popup: null, gift: false });
  };
  return (
    <Page>
      <SEO {...seo} />
      <Popups
        which={popup.popup}
        gift={popup.gift}
        handleClose={handleClose}
        goToCheckout={goToCheckout}
      />
      <HelloBarScroll scrollInHeight={1025}>
        <div className={classes.hellobarscroll}>
          <Container>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
              <Grid item xs={6} sm={3}>
                <div className={classes.watchBtnContainer}>
                  {isBarMitzvah ? (
                    <Button
                      variant="outlined"
                      size="large"
                      className={classes.watchTrailerBtn}
                      onClick={() => showSalePopup({ gift: true })}
                      fullWidth
                    >
                      gift
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={`${classes.watchTrailerBtn} wistia-popover[height=720,playerColor=f18744,width=1280]`}
                      href="https://fast.wistia.net/embed/iframe/kx06tvq3u5?popover=true"
                      underline="none"
                    >
                      watch the trailer
                    </Button>
                  )}
                </div>
              </Grid>
              <Hidden only="xs">
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.hbTitle}>
                    Bar and Bat Mitzvah Prep Course
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item xs={6} sm={3}>
                <div className={classes.accessBtnContainer}>
                  {isBarMitzvah ? (
                    <Button
                      component={GatsbyLink}
                      to="/bar-bat-mitzvah/lesson-plan"
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.getStartedBtn}
                      fullWidth
                    >
                      View Course
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.getStartedBtn}
                      onClick={showSalePopup}
                    >
                      get all-access
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </HelloBarScroll>
      <BarBatMitzvahHero />
      <BarBatMitzvahInfo
        goToCheckout={goToCheckout}
        showSalePopup={showSalePopup}
        isBarMitzvah={isBarMitzvah}
      />
      <BarBatMitzvahPresenters />
      <BarBatMitzvahWhy showSalePopup={showSalePopup} />
      <BarBatMitzvahAbout />
      <BarBatMitzvahTestimonials />
      <BarBatMitzvahBoysGirls />
      <BarBatMitzvahFindFit />
      <BarBatMitzvahOrderNow
        goToCheckout={goToCheckout}
        showSalePopup={showSalePopup}
        isBarMitzvah={isBarMitzvah}
      />
      <BarBatMitzvahSharing />
    </Page>
  );
};

export default BM;
