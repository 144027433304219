import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import Container from '~components/Container';
import BMOrderNowBgImage from '~images/bar-bat-mitzvah/bm-order-now-bg.jpg';
import BMConfetti1Image from '~images/bar-bat-mitzvah/boy-bar-mitzvah-ceremony-process.png';
import BMConfetti2Image from '~images/bar-bat-mitzvah/female-bar-mitzvah-ceremony-process.png';
import BMConfetti3Image from '~images/bar-bat-mitzvah/jewish-coming-of-age-ceremony-celebration.png';

const useStyles = makeStyles({
  bbm_order_now: {
    padding: '100px 0',
    backgroundColor: '#ffdaa2',
    backgroundImage: `url(${BMOrderNowBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    textAlign: 'center',
    backgroundAttachment: 'relative',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover'
  },
  title_order_now: {
    color: '#5a6175',
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0'
  },
  plans: {
    paddingTop: '40px'
  },
  order_plan: {
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: '#ffffff',
    padding: '30px',
    margin: '20px',
    borderRadius: '4px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .plan-price': {
      fontSize: '40px',
      fontWeight: 'bold',
      color: '#5a6175'
    },
    '& h3': {
      color: '#5a6175',
      fontSize: '22px',
      fontWeight: '700',
      margin: '0'
    },
    '& p': {
      color: '#5a6175',
      flexGrow: '1',
      fontSize: '18px',
      paddingTop: '10px',
      margin: '0'
    },
    '& .button': {
      backgroundColor: '#5a6175',
      fontWeight: '600',
      fontSize: '20px',
      padding: '12px 0'
    },
    '&.all-access': {
      '& h3': {
        color: '#438dce'
      },
      '& .plan-price': {
        color: '#438dce'
      },
      '& .button': {
        backgroundColor: '#438dce'
      }
    }
  },
  confetti_1: {
    position: 'absolute',
    top: '0',
    left: '10%',
    pointerEvents: 'none'
  },
  confetti_2: {
    position: 'absolute',
    top: '15%',
    right: '15%',
    pointerEvents: 'none'
  },
  confetti_3: {
    position: 'absolute',
    bottom: '-12%',
    left: '40%',
    pointerEvents: 'none'
  },
  cta_title: {
    fontFamily: "'Raleway', sans-serif",
    color: '#4a4a4a',
    fontSize: '1.2rem',
    fontWeight: '600',
    paddingBottom: '6px',
    marginBottom: '12px',
    margin: '0'
  },
  or_divider: {
    position: 'relative',
    lineHeight: '16px',
    fontSize: '12px',
    letterSpacing: '1px',
    margin: '15px 0',
    color: '#4a4a4a',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '0',
      width: '100%',
      height: '1px',
      background: '#cecbc8'
    },
    '& span': {
      position: 'relative',
      background: '#fff',
      padding: '0 7px'
    }
  },
  gift_btn: {
    backgroundColor: 'transparent',
    color: '#5a6175',
    border: '2px solid #5a6175',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '10px 0'
  },
  all_access_btn: {
    backgroundColor: '#438dce',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: '600',
    padding: '12px 0'
  }
});

const BarBatMitzvahOrderNow = props => {
  const classes = useStyles();

  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const { goToCheckout, isBarMitzvah, showSalePopup } = props;
  return (
    <section className={classes.bbm_order_now}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={isSmallerThanMd ? 12 : 10}>
            <Typography component="h2" className={classes.title_order_now}>
              Get Started Now
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems={isBarMitzvah ? 'center' : 'stretch'}
              className={classes.plans}
              spacing={isSmallerThanMd ? 1 : 8}
            >
              {!isBarMitzvah && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classNames(classes.order_plan, 'all-access')}>
                      <Typography component="h3">All-Access</Typography>
                      <div className="plan-price">$180</div>
                      <Typography component="p">
                        You get the Bar and Bat Mitzvah Prep Course (a $150 value!) PLUS a 1-Year
                        recurring Premium Membership (a $180 value!) to Aleph Beta!
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className="button"
                        onClick={() => goToCheckout({ isAllAccess: true, isGift: false })}
                        fullWidth
                      >
                        Get All-Access
                      </Button>
                    </div>
                  </Grid>
                </>
              )}
              {isBarMitzvah && (
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classNames(classes.order_plan, 'all-access')}>
                    <Typography variant="h5" component="h5" className={classes.cta_title}>
                      Thanks for purchasing!
                    </Typography>
                    <Button
                      component={GatsbyLink}
                      to="/bar-bat-mitzvah/lesson-plan"
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.all_access_btn}
                      fullWidth
                    >
                      View Course
                    </Button>

                    <div className={classes.or_divider}>
                      <span>OR</span>
                    </div>

                    <Button
                      variant="outlined"
                      size="large"
                      className={classes.gift_btn}
                      onClick={() => showSalePopup({ gift: true })}
                      fullWidth
                    >
                      gift
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.confetti_1}>
          <img src={BMConfetti1Image} alt="Boy Bar Mitzvah ceremony preparation" />
        </div>
        <div className={classes.confetti_2}>
          <img src={BMConfetti2Image} alt="Female Bar Mitzvah ceremony planning" />
        </div>
        <div className={classes.confetti_3}>
          <img src={BMConfetti3Image} alt="Jewish coming of age ceremony celebration" />
        </div>
      </Container>
    </section>
  );
};

BarBatMitzvahOrderNow.propTypes = {
  goToCheckout: PropTypes.func.isRequired,
  showSalePopup: PropTypes.func.isRequired,
  isBarMitzvah: PropTypes.bool
};

BarBatMitzvahOrderNow.defaultProps = {
  isBarMitzvah: false
};

export default BarBatMitzvahOrderNow;
