import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import BMAboutBgImage from '~images/bar-bat-mitzvah/bm-about-bg.png';

const useStyles = makeStyles({
  bbm_about: {
    padding: '100px 0',
    backgroundColor: '#626a7f',
    backgroundImage: `url(${BMAboutBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    textAlign: 'center',
    backgroundAttachment: 'relative',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover'
  },
  title_about: {
    fontFamily: "'Raleway', sans-serif",
    color: '#ffffff',
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    margin: '0'
  },
  about_grid: {
    padding: '40px 0 0 0'
  },
  course_feature: {
    backgroundColor: '#ffffff',
    padding: '40px',
    borderRadius: '4px',
    height: '100%',
    '& h3': {
      fontFamily: "'Raleway', sans-serif",
      textTransform: 'uppercase',
      fontSize: '1.1rem',
      fontWeight: '700',
      color: '#5a6175',
      padding: '12px 0',
      textAlign: 'center',
      margin: '0',
      '&.green': {
        color: '#76c79d'
      },
      '&.yellow': {
        color: '#f0be6d'
      },
      '&.orange': {
        color: '#f07d31'
      },
      '&.purple': {
        color: '#8696e9'
      },
      '&.brown': {
        color: '#8b7472'
      },
      '&.blue': {
        color: '#438dce'
      }
    },
    '& p': {
      fontSize: '0.9rem',
      fontWeight: '400',
      color: '#5a6175',
      padding: '0 20px',
      textAlign: 'center',
      margin: '0'
    }
  },
  all_access_notice: {
    fontFamily: "'Roboto', sans-serif",
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '14px',
    textAlign: 'center',
    padding: '20px 0 0 0'
  }
});

const BarBatMitzvahAbout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section className={classes.bbm_about}>
      <Container>
        <h2 className={classes.title_about}>About The Course</h2>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          className={classes.about_grid}
          spacing={isSmallerThanMd ? 2 : 5}
        >
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.course_feature}>
              <Typography component="h3" className="green">
                six animated videos
              </Typography>
              <Typography component="p">
                The heart of the course that includes interactive questions and discussion points
                along the way
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.course_feature}>
              <Typography component="h3" className="yellow">
                illustrated study guide
              </Typography>
              <Typography component="p">
                Fits into your schedule. Print it out, read it on Shabbat or whenever is most
                convenient. You decide!
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.course_feature}>
              <Typography component="h3" className="orange">
                parents&lsquo; manual
              </Typography>
              <Typography component="p">
                To guide you through the course and give you resources to make this day even more
                special
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.course_feature}>
              <Typography component="h3" className="purple">
                exclusive podcast
              </Typography>
              <Typography component="p">
                An insider’s view into the course and the meaning of Bar and Bat Mitzvah with our
                writers
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.course_feature}>
              <Typography component="h3" className="brown">
                learning community
              </Typography>
              <Typography component="p">
                Join our discussion forum with other kids and parents taking the course and our own
                top notch educators
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.course_feature}>
              <Typography component="h3" className="blue">
                amazing parsha videos!
              </Typography>
              <Typography component="p">
                Everything you need for your child’s parsha, including 3 videos, a study guide,
                podcast episode and access to our library of over 300 videos!*
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={8}>
            <Typography component="p" className={classes.all_access_notice}>
              *All Bar and Bat Mitzvah students get parsha resources, but only the All Access Plan
              includes a one year subscription to Aleph Beta and access to our extensive online
              video library.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default BarBatMitzvahAbout;
