/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Social from '~components/social/Social';

import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  bbm_sharing: {
    padding: '40px 0',
    backgroundColor: '#ffffff'
  },
  share_text: {
    color: '#5a6175',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'right',
    margin: '0 10px 0 0'
  }
}));

const BarBatMitzvahSharing = () => {
  const classes = useStyles();
  return (
    <section className={classes.bbm_sharing}>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={6}>
            <Typography component="p" className={classes.share_text}>
              share with your friends:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Social
              size={40}
              round={false}
              style={{ margin: '0 4px' }}
              title="Check out AlephBeta’s​ NEW Bar and Bat Mitzvah course!"
              url="/bar-bat-mitzvah"
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default BarBatMitzvahSharing;
